import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import "../dev-styles.scss";
import { ButtonContainer } from "../../../components/mdx/mdx-button-container/index.tsx";
import { SkillStack, addColorToSkills } from "../../../components/skillStack/index.tsx";
export const buttons = [{
  text: "review component repo",
  url: "https://github.com/shazamazon/module-reviews"
}, {
  text: "microservice proxy repo",
  url: "https://github.com/shazamazon/proxy-reviews"
}];
export const tags = ["React", "Node", "Javascript", "AWS", "EC2", "PM2", "MongoDB", "Express", "ARIA", "Postgres"];
export const _frontmatter = {};
const layoutProps = {
  buttons,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className='mdx-default-wrapper'>
      <h1>{`Shazamazon`}</h1>
      <p>{`Shazamazon is a full stack item description page modeled after Amazon. It is composed of nine individually deployed micro-services that are stitched together in order to architect scalable components with separation of concerns. My major contributions to this project are as follows:`}</p>
      <ul>
        <li parentName="ul">{`Creating the reviews component - a component that has its own front-end, back-end and database to handle review display, review submission, and helpful demarcations. The component also features a profanity filter, is WAI-ARIA compliant for voice-reader access and communicates to other components as reviews are submitted.`}</li>
        <li parentName="ul">{`Scaled the Carousel to handle 10 million items and 1000 RPS by implementing load balancing across multiple server instances.`}</li>
        <li parentName="ul">{`Created a proxy to stitch all nine components together.`}</li>
      </ul>
      <ButtonContainer buttons={buttons} mdxType="ButtonContainer" />
      <SkillStack skills={addColorToSkills(tags)} mdxType="SkillStack" />
      <iframe className="mdx-video mdx-iframe" src="https://www.youtube.com/embed/jEG-kVc52FE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      