import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import AdVocation from "./advocation";
import ChickPeach from "./chickpeach";
import Shazamazon from "./shazamazon";
import VocalCodes from "./vocal-codes";
import WebColorGenerator from "./web-color-generator";
import WasNotImpostor from "./was-not-impostor";
import FaceBrowserExtension from "./face-browser-extension";
import SideBar from '../../../components/sideBar';
import MobileNav from '../../../components/mobileNav';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className='dev-pageWrapper'>
  <SideBar pageString={'dev'} currentPath={'/dev/applications/'} mdxType="SideBar" />
      <div className='dev-contentWrapper'>
        <div className='dev-sectionHeader'>
          <h1>{`Applications`}</h1>
          <p>{`Here are the digital stories and experiences I’ve brought to life.`}</p>
        </div>
        <hr></hr>
        <div className='dev-applications'>
          <FaceBrowserExtension mdxType="FaceBrowserExtension" />
          <hr></hr>
          <WasNotImpostor mdxType="WasNotImpostor" />
          <hr></hr>
          <VocalCodes mdxType="VocalCodes" />
          <hr></hr>
          <ChickPeach mdxType="ChickPeach" />
          <hr></hr>
          <WebColorGenerator mdxType="WebColorGenerator" />
          <hr></hr>
          <Shazamazon mdxType="Shazamazon" />
          <hr></hr>
          <AdVocation mdxType="AdVocation" />
        </div>
      </div>
      <MobileNav type='dev' mdxType="MobileNav" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      