import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import "../dev-styles.scss";
import { ButtonContainer } from "../../../components/mdx/mdx-button-container/index.tsx";
import { SkillStack, addColorToSkills } from "../../../components/skillStack/index.tsx";
export const buttons = [{
  text: "git repo",
  url: "https://github.com/hratx-blue-ocean/chickpeach"
}, {
  text: "live demo",
  url: "http://ec2-18-220-35-143.us-east-2.compute.amazonaws.com/"
}];
export const tags = ['React', 'React Router', 'Node', 'Javascript', 'AWS', 'EC2', 'PM2', 'Firebase', 'MySQL', 'Express', 'ARIA', 'Javascript', 'Grommet', 'Docker'];
export const _frontmatter = {};
const layoutProps = {
  buttons,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className='mdx-default-wrapper'>
      <h1>{`Chickpeach`}</h1>
      <p>{`Chickpeach is your one stop meal planning, recipe discovery and grocery-management app. Users are able to:`}</p>
      <ul>
        <li parentName="ul">{`Search for recipes and save them to a menu or favorites`}</li>
        <li parentName="ul">{`Input custom allergens and dislikes and have those ingredients never show up in search or the menu`}</li>
        <li parentName="ul">{`Generate custom shopping lists based on selected menu items`}</li>
        <li parentName="ul">{`Adjust meal prepping to accommodate multiple persons, dietary restrictions and popular diets`}</li>
      </ul>
      <p>{`The app features a clean UI that is WAI-ARIA compliant. Chickpeach is fully responsive and can be taken on the go or browsed as a rich desktop experience. `}</p>
      <ButtonContainer buttons={buttons} mdxType="ButtonContainer" />
      <SkillStack skills={addColorToSkills(tags)} mdxType="SkillStack" />
      <iframe className='mdx-video mdx-iframe' src="https://www.youtube.com/embed/1Q_Wb6s6fA8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      